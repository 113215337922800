<div class="notification-dialog p-0">
    <div class="top">
        <div class="top-inner">
            <div class="body-large white">{{ config?.enableEditUserGroup ? 'Edit User Group': 'Create New User Group' }}
            </div>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="notification-dialog-outer">
        <div class="input-con-full" [hidden]="loadingFilter">
            <form [formGroup]="editForm">
                <!--================= User Group Name =================-->
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>User Group Name</mat-label>
                    <input placeholder="Enter a memorable group name" matInput required autocomplete="off"
                        formControlName="groupName" />
                </mat-form-field>
                <!--================= Description =================-->
                <mat-form-field appearance="outline" class="input-form-field">
                    <mat-label>Description</mat-label>
                    <textarea class="description-textarea" matInput required autocomplete="off"
                        formControlName="description"></textarea>
                </mat-form-field>
            </form>
            <div class="form-divider"></div>
            <div [formGroup]="searchForm">
                <app-search-user-group [groupForm]="searchForm" [maxNestingLevel]="4" [tagsList]="tagsList">
                </app-search-user-group>
            </div>
        </div>
    </div>
    <div class="button-layout">
        <!-- <div class="h5 gray">{{ totalUsersCnt }} users</div> -->
        <div>
            <button class="secondary-button-warn mr-10" (click)="onSave(true)"
                *ngIf="config?.enableEditUserGroup">DELETE</button>
            <button class="primary-button" (click)="onSave()"
                [disabled]="!searchForm.get('conditions')?.length || !editForm.valid">{{ config?.enableEditUserGroup ?
                'SAVE CHANGES' : 'CREATE USER GROUP' }}</button>
        </div>
    </div>
</div>