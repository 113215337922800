import { Component, AfterViewInit, OnDestroy, ViewChild, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BadgesService } from '../../badges/badges.service';
import { BadgesResponse, Badge } from '../../badges/badges.model';

@Component({
  selector: 'app-badges-widget',
  templateUrl: './badges-widget.component.html',
  styleUrls: ['./badges-widget.component.scss']
})
export class BadgesWidgetComponent implements AfterViewInit, OnDestroy {
  public badges: Badge[] = [];
  public totalCount: number = 0;
  public lockedBadgesArray: number[] = [1, 2, 3, 4, 5];
  public badgeSize: number = 72;
  public badgesLoaded: boolean = false;

  private resizeHandler: () => void;

  @Input() hasEnclosingDiv: boolean = false;
  @ViewChild('badgesContainer') badgesContainer?: ElementRef;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    private badgesService: BadgesService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.resizeHandler = this.calculateBadgeSize.bind(this);
  }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.getBadgesForWidget();
  }

  ngAfterViewInit() {
    window.addEventListener('resize', this.resizeHandler);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  navigateToBadges() {
    this.router.navigate(['sms/badges']);
  }

  get badgesText() {
    if (this.totalCount === 0 || this.totalCount === undefined) {
      return '0 badges unlocked';
    } else if (this.totalCount === 1) {
      return '1 badge unlocked';
    }
    return `${this.totalCount} badges unlocked!`;
  }

  get placeholderBadgeImage() {
    return (index: number) => `assets/badges/badge${index}.png`;
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private calculateBadgeSize() {
    if (this.badgesContainer?.nativeElement) {
      const containerWidth = this.badgesContainer.nativeElement.offsetWidth;
      const calculatedSize = (containerWidth / 5) - 2;  // Subtracting 2px to account for margin
      this.badgeSize = Math.min(Math.max(calculatedSize, 40), 100); // Min 40px, Max 100px
    }
  }

  private getBadgesForWidget(page: number = 1, limit: number = 10) {
    this.badgesService.getAllBadges(page, limit, false).subscribe((res: BadgesResponse) => {
      this.badgesLoaded = true;
      this.badges = res.badges;
      this.totalCount = res.totalCount;
      // Calculate badge size after badges are loaded
      setTimeout(() => {
        this.calculateBadgeSize();
        this.cdr.detectChanges();
      });
    });
  }
}
