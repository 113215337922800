<div class="avatar-container">
  <div class="enabled mt-4">
    <div class="header-sec d-flex justify-content-between">
      <div class="title h3 fs-16 mb-4">Enabled Avatars</div>
      <input type="file" (change)="onFileSelectedFromUser($event, fileInput)" accept="image/png, image/jpeg" id="fileInput"  style="visibility: hidden;" #fileInput>
      <div class="action-btn">
        <button mat-stroked-button class="primary-button" (click)="fileInput.click()">
          <app-svg-icon class="mr-1" fill="var(--color-white)" src="icons/plus-icon.svg" height="16"></app-svg-icon>
          <span>ADD AVATAR</span>
        </button>
      </div>
    </div>
    <div class="body-sec d-flex flex-wrap gap-32">
      <div *ngFor="let avatar of enabledAvatars?.data?.avatars" (click)="onAvatarClick(avatar)">
        <img [src]="getAvatarUrl(avatar)" alt="Avatar Image">
      </div>
    </div>
    <app-paginator-ui #paginator (onPaginationEvent)="onPaginationEvent($event)" [data]="paginatorDataEnabled" itemTitle="enabled avatars">
    </app-paginator-ui>
    <div class="no-disabled mt-32" *ngIf="!enabledAvatars?.data?.avatars?.length">
      There are no enabled avatars.
    </div>
  </div>
  <hr class="mb-40 mt-40">
  <div class="disabled">
    <div class="header-sec d-flex justify-content-between">
      <div class="title h3 fs-16 mb-4">Disabled Avatars</div>
    </div>
    <div class="body-sec d-flex flex-wrap gap-32">
      <div *ngFor="let avatar of disabledAvatars?.data?.avatars" (click)="onAvatarClick(avatar)">
        <img [src]="getAvatarUrl(avatar)" alt="Avatar Image">
      </div>
      <app-paginator-ui #paginatorDisabled class="w-100" (onPaginationEvent)="onPaginationEventDisabled($event)" [data]="paginatorDataDisabled" itemTitle="disabled avatars">
      </app-paginator-ui>
      <div class="no-disabled" *ngIf="!disabledAvatars?.data?.avatars?.length">
        There are no disabled avatars.
      </div>
    </div>
  </div>
</div>
