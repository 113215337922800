<div class="smscon" *ngIf="client">
    <div class="h3 black " style="margin-bottom: 15px;">SkillPrism Application Settings</div>
    <div class="admin-panel" *ngIf="client">
        <app-general-sms-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-general-sms-settings>
    </div>
    <div class="admin-panel-half-con ">
        <div class="admin-panel-half">
            <div class="h3 black ">Skill Hierarchy</div>
            <div *ngIf="client" class="skill-categories-con admin-form">
                <app-editable-table #skillHierarchy [canEdit]="canEdit" [enableDelete]="true" class="sms-editable-table"
                    [columns]="skillHierarchyColumns" [dataSource]="client.smsSettings.skillHierarchy"
                    [footNotes]="'You need a minimum of 2 skill hierarchy levels. The first level is usually just the Skill'"
                    [saveButtonText]="'Save'" (onSave)="onSkillHierarchySave($event)" [addButtonText]="'Add'"
                    [enableDrag]="false">
                </app-editable-table>
            </div>
        </div>
        <div class="admin-panel-half">
            <div class="h3 black ">Skill Categories</div>
            <div *ngIf="client" class="skill-categories-con admin-form">
                <app-editable-table #skillCategories [canEdit]="canEdit" [enableDelete]="true"
                    class="sms-editable-table" [columns]="skillCategoriesColumns"
                    [dataSource]="client.smsSettings.skillCategories"
                    [footNotes]="'You can add maximum 7 categories. Drag and drop categories to change order'"
                    [saveButtonText]="'Save'" (onSave)="onSkillCategoriesSave($event)" [addButtonText]="'Add'"
                    [enableDrag]="true" [maxRows]="7">
                </app-editable-table>
            </div>
        </div>
    </div>
    <div class="admin-panel">
        <app-skill-management-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)"></app-skill-management-settings>
    </div>
    <div class="admin-panel">
        <app-skill-taxonomy-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-skill-taxonomy-settings>
    </div>
    <div class="admin-panel" *ngIf="client.smsSettings.skillCategories.length">
        <div class="h3 black ">Skill Category-wise Settings</div>
        <mat-tab-group class="admin-tabs" dynamicHeight [ngClass]="{'hide-tabs-header':numTabs<2}"
            animationDuration="75ms">
            <mat-tab *ngFor="let category of client.smsSettings.skillCategories">
                <ng-template mat-tab-label>
                    {{category.name}}
                </ng-template>
                <ng-template matTabContent>
                    <app-category-wise-settings [canEdit]="canEdit" [category]="category" [client]="client"
                        (onSave)="saveData($event)" [syncButtonText]="syncButtonText">
                    </app-category-wise-settings>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="admin-panel" *ngIf="client">
        <app-skill-updated-source [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-skill-updated-source>
    </div>

    <div class="admin-panel" *ngIf="client&&(client.features.admin.certifications||'No Access')!='No Access'">
        <app-certifications-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-certifications-settings>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.admin.projects||'No Access')!='No Access'">
        <app-projects-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-projects-settings>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.admin.courses||'No Access')!='No Access'">
        <app-courses-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-courses-settings>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.admin.specializations||'No Access')!='No Access'">
        <app-specializations-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-specializations-settings>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.admin['designations']||'No Access')!='No Access'">
        <app-designation-paths-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-designation-paths-settings>
    </div>
    <div class="admin-panel" *ngIf="client && hrmsMastersAccessLevel != 'No Access'">
        <app-hrms-masters-settings [hrmsMastersConfig]="hrmsMastersConfig" (onSave)="saveData($event)">
        </app-hrms-masters-settings>
    </div>
    <div class="admin-panel" *ngIf="client && customTagsAccessLevel !== 'No Access'">
        <app-custom-tags-settings [customTagsConfig]="customTagsConfig" (onSave)="saveData($event)">
        </app-custom-tags-settings>
    </div>
    <div class="admin-panel" *ngIf="client && customTagsAccessLevel !== 'No Access'">
        <app-user-custom-tags #userCustomTagsComponent [client]="client" (onSave)="saveData($event)">
        </app-user-custom-tags>
    </div>
    <div class="admin-panel" *ngIf="client && customTagsAccessLevel !== 'No Access' && isStaffingRoleEnabled">
        <app-staffing-role-settings (onSave)="saveData($event)"></app-staffing-role-settings>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.admin['skill-clusters']||'No Access')!='No Access'">
        <app-demand-units-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-demand-units-settings>
    </div>
    <div class="admin-panel" *ngIf="client">
        <app-aliases [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)"></app-aliases>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.sms['skillRecommendation']||'No Access')!='No Access'">
        <app-recommendation-settings [canEdit]="canEdit" [client]="client"
            (onSave)="saveData($event)"></app-recommendation-settings>
    </div>
    <app-skill-ratings-settings></app-skill-ratings-settings>
    <div class="admin-panel" *ngIf="client&&(client.features.admin['resumeBuilder']||'No Access')!='No Access'">
        <app-resume-builder-settings [canEdit]="canEdit" [client]="client"
        (onSave)="saveData($event)"></app-resume-builder-settings>
    </div>
    <div class="admin-panel" *ngIf="client&&(client.features.sms['dashboards']||'No Access')!='No Access'">
        <app-reports-center-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-reports-center-settings>
    </div>
    <div class="admin-panel" *ngIf="client && (client.features.sms['showSocialEndorsements'] || 'No' ) != 'No'">
        <app-p2p-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)"></app-p2p-settings>
    </div>
    <div class="admin-panel">
        <app-avatar-layout [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)"></app-avatar-layout>
    </div>
    <div class="admin-panel" *ngIf="client && (client.features.admin['gamification'] || 'No Access') != 'No Access'">
        <app-gamification-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)"></app-gamification-settings>
    </div>
    <div class="admin-panel" *ngIf="client && (client.features.admin['customFeedOnHomePage'] || 'No Access') == 'No Access'">
        <app-homepage-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)"></app-homepage-settings>
    </div>
    <div class="admin-panel" *ngIf="isLearningSettingsEnabled">
        <app-learning-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData($event)">
        </app-learning-settings>
    </div>
</div>
