import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  private avatarUrlSubject: BehaviorSubject<string>;
  private avatarImageUrlSubject$: Subject<string> = new Subject<string>();

  constructor(private ds: DataService) {
    this.avatarUrlSubject = new BehaviorSubject(null);
  }

  public get avatarUrl$(): Observable<string> {
    return this.avatarUrlSubject.asObservable();
  }

  public get avatarImageUrl$(): Observable<string> {
    return this.avatarImageUrlSubject$.asObservable();
  }

  public setAvatarImageUrl(updatedUrl: string): void {
    this.avatarImageUrlSubject$.next(updatedUrl);
  }

  public setAvatarUrl(updatedUrl: string): void {
    this.avatarUrlSubject.next(updatedUrl);
  }

  public hasAccessAvatar(updatedClient?: Client): boolean {
    const client = updatedClient || this.ds.client;
    return (client?.smsSettings?.['allow3dAvatars'] || 'No') !== 'No';
  }

  public handleFileSelection(event: any, fileInput: HTMLInputElement): void {
    const file = (event.target as HTMLInputElement).files?.[0];

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const imageURL = e.target?.result as string;
      if (imageURL) {
        const fileSizeInKB = file.size / 1024;
        if (fileSizeInKB > 100) {
          fileInput.value = '';
        } else {
          this.setAvatarImageUrl(imageURL);
        }
      }
    };

    reader.readAsDataURL(file);
  }
}
