import { Injectable } from '@angular/core';
import { GoalIcon, GoalStatusType, GoalType } from '../modules/my-goals/my-goals-layout';
import { GoalStatus } from '../modules/my-goals/components/goals-track-list/goals-track-list.component';
import { IGoalMap, IUserInfo } from '../modules/team-goals/team-goals-layout';
import { IGoal } from '../modules/admin/admin-goal-management/components/goals';
import { IMyGrowthPlan, IMyGrowthResponse } from '../modules/my-goals/components/my-growth-plans-card';
import { DataService } from './data.service';
import { Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { API_ENDPOINT } from '../constants/api-endpoint.constants';
import { UtilitiesService } from './utilities.service';
import { Goal_Chip } from '../constants/idp-filter.constants';

export interface IDevelopmentPlanStatus {
  id: number;
  name: string;
}

const progressStatusSortOrder = {
  GOAL_SET: 1,
  DELAYED: 2,
  COMPLETED: 3
};

const Status = {
  NOT_STARTED: 'NOT STARTED',
  GOAL_SETTING: 'GOAL SETTING',
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  ARCHIVED: 'ARCHIVED',
}

@Injectable({
  providedIn: 'root'
})
export class GoalService {

  public DEFAULT_GOAL_CARDS = [
    { key: 'IN_REVIEW', label: 'IN REVIEW', value: 0 },
    { key: 'GOAL_SET', label: 'GOAL SET', value: 0 },
    { key: 'COMPLETED', label: 'COMPLETED', value: 0 },
    { key: 'DELAYED', label: 'DELAYED', value: 0 }
  ];

  public statusesDevelopmentPlan: IDevelopmentPlanStatus[] = [
    { id: 0, name: 'Any' },
    { id: 1, name: Status.NOT_STARTED },
    { id: 2, name: Status.GOAL_SETTING },
    { id: 3, name: Status.ACTIVE },
    { id: 4, name: Status.COMPLETED }
  ];

  public progressStatus = {
    IN_REVIEW: 'IN REVIEW',
    GOAL_SET: 'GOAL SET',
    COMPLETED: 'COMPLETED',
    DELAYED: 'DELAYED',
    IN_COMPLETE: 'IN COMPLETE',
    REJECTED: 'REJECTED',
    ARCHIEVED: 'ARCHIEVED',
  }

  public goalTypes = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Skill' },
    { id: 2, name: 'Role' },
    { id: 3, name: 'Specialization' },
    { id: 4, name: 'Custom' },
    { id: 5, name: 'Course' },
    { id: 6, name: 'Certification' }
  ];

  public goalStatuses = [
    { id: 0, key: 'ANY', name: 'Any' },
    { id: 1, key: 'GOAL_SET', name: 'Goal Set' },
    { id: 2, key: 'IN_REVIEW', name: 'In Review' },
    { id: 3, key: 'COMPLETED', name: 'Completed' },
    { id: 4, key: 'DELAYED', name: 'Delayed' },
  ];

  public growthPlans = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Assigned' },
    { id: 2, name: 'Recommended' }
  ];

  public goalCategories = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Assigned' },
    { id: 2, name: 'Recommended' }
  ];

  public goalTypeIconsMap: { [key: string]: string } = {
    skill:          'electric_bolt',
    custom:         'album',
    course:         'menu_book',
    certification:  'military_tech',
    specialization: 'school',
    role:           'assignment_ind',
  };

  // public goalTypeLabelsMap: { [key: string]: string } = {
  //   skill: 'Develop a skill',
  //   course: 'Complete a course',
  //   certification: 'Complete a certification',
  //   specialization: 'Complete a specialization',
  //   role: 'Develop for a role',
  //   custom: 'Custom Goal',
  // };

  public readonly STATUSES = ['GOAL_SET', 'IN_REVIEW', 'COMPLETED', 'DELAYED'];
  public readonly IN_REVIEW_STATUSES = ['REJECTED', 'PENDING', 'REVISED'];

  public goalStatusIconMap: any = {
    IN_REVIEW: GoalIcon.PENDING,
    GOAL_SET: GoalIcon.ALBUM,
    COMPLETED: GoalIcon.TICK,
    DELAYED: GoalIcon.SCHEDULE
  }

  public goalStatusIconColorMap = {
    [GoalType.SET]: 'var(--primary-color)',
    [GoalType.DELAYED]: 'var(--warning-color-dark)',
    [GoalType.REVISED]: 'var(--warning-yellow)',
    [GoalType.IN_REVIEW]: 'var(--color-black-pearl)',
  };

  public goalStatusColors: { [key in GoalStatus]: string } = {
    [GoalStatus.NotStarted]: '#051C2C',
    [GoalStatus.InProgress]: '#051C2C',
    [GoalStatus.Completed]: '#24A34D',
    [GoalStatus.Archived]: '#051C2C',
    [GoalStatus.RevisedByManager]: '#051C2C',
    [GoalStatus.Revised]: '#4A3700',
    [GoalStatus.Rejected]: '#7A1016',
    [GoalStatus.PendingApproval]: '#051C2C',
    [GoalStatus.GoalSet]: '#051C2C',
    [GoalStatus.Delayed]: '#DA1E28',
    [GoalStatus.AtRisk]: '#7A1016',
    [GoalStatus.OnTrack]: '#EB6300',
    [GoalStatus.InReview]: '#0E0D0F',
    [GoalStatus.Not_Required]: '#0E0D0F',
    [GoalStatus.Recommended]: '#0E0D0F'
  };

  public goalStatusBGColors: { [key in GoalStatus]: string } = {
    [GoalStatus.NotStarted]: '#E6E6EB',
    [GoalStatus.InProgress]: '#E6E6EB',
    [GoalStatus.Completed]: '#A8F0BD',
    [GoalStatus.Archived]: '#E6E6EB',
    [GoalStatus.RevisedByManager]: '#E6E6EB',
    [GoalStatus.Revised]: '#FEF0C2',
    [GoalStatus.Rejected]: '#FED8D9',
    [GoalStatus.PendingApproval]: '#E6E6EB',
    [GoalStatus.GoalSet]: '#E6E6EB',
    [GoalStatus.Delayed]: '#FED8D9',
    [GoalStatus.AtRisk]: '#FED8D9',
    [GoalStatus.OnTrack]: '#FFD8BA',
    [GoalStatus.InReview]: '#F0F0F5',
    [GoalStatus.Not_Required]: '#E0E0E0',
    [GoalStatus.Recommended]: '#0E0D0F'
   };

  private growthPlansData: IMyGrowthResponse;

  constructor(
    private ds: DataService,
    private util: UtilitiesService
  ) { }

  public getColor(type: GoalStatusType): string {
    switch (type) {
      case GoalStatusType.PRIMARY:
        return 'var(--primary-color-light)';
      case GoalStatusType.INFO:
        return '#051C2C';
      case GoalStatusType.WARNING:
        return '#F59E0B';
      case GoalStatusType.DANGER:
        return 'var(--warning-color-dark)';
      case GoalStatusType.SUCCESS:
        return 'var(--color-green-dark)';
    }
  }

  public getGoalStatusColors(isOverview: boolean = true) {
    const colors = this.goalStatusColors;
    if(!isOverview) {
      colors.COMPLETED = '#034216';
    }
    return colors;
  }

  public getGoalStatusBGColors(isOverview: boolean = true) {
    return this.goalStatusBGColors;
  }

  public createUserGoalsStatusMap(goals: IGoalMap, userGoalsStatusMap: Record<string, any>): Record<string, any> {
    Object.keys(goals).forEach(userId => {
        const userGoals = goals[userId] || [];

        // Initialize counts for each status
        userGoalsStatusMap[userId] = {
            COMPLETED: 0,
            GOAL_SET: 0,
            INCOMPLETE: 0,
            REJECTED: 0,
            DELAYED: 0,
            IN_REVIEW: 0
        };

        userGoals.forEach(goal => {
            // Increment the count based on progressStatus
            if (goal.progressStatus in userGoalsStatusMap[userId]) {
                userGoalsStatusMap[userId][goal.progressStatus]++;
            }
        });
    });

    return userGoalsStatusMap;
 }

  public getAllSortedGoalsWithProfileImg(goalsData: IGoalMap, userInfo: { [userId: string]: IUserInfo }): IGoal[] {
    const keys: any = Object.keys(goalsData);
    const allGoals = keys.flatMap(userId => {
      const user = userInfo[userId];
      if (!user) return [];

      return goalsData[userId].map(goal => ({
        ...goal,
        profileImg: user.profileImg
      }));
    });

    // Sort all goals first by progressStatus and then by goalDeadline
    allGoals.sort((a: IGoal, b: IGoal) => {
      const statusComparison = progressStatusSortOrder[a.progressStatus] - progressStatusSortOrder[b.progressStatus];
      if (statusComparison === 0) {
        // If progressStatus is the same, sort by goalDeadline
        return new Date(a.goalDeadline).getTime() - new Date(b.goalDeadline).getTime();
      }
      return statusComparison;
    });

    return allGoals;
  }

  public mapUserInfoToObject(userInfo: IUserInfo[]): { [key: string]: IUserInfo } {
    return userInfo?.reduce((acc, user) => {
      if (user.userId) {
        user.initials = this.util.getShortName(user.name);
        acc[user.userId] = user;
      }
      return acc;
    }, {} as { [key: string]: IUserInfo });
  }

  public getGrowthPlansData(): IMyGrowthResponse {
    return this.growthPlansData;
  }

  public setGrowthPlansData(data: IMyGrowthResponse): void {
      this.growthPlansData = {...data};
  }

  public getGrowthPlans(clientId: string, payload: any): Observable<IMyGrowthResponse> {
    if (this.growthPlansData) {
      return of(this.growthPlansData);
    }

    return this.ds.careerPrismDataPostApi(API_ENDPOINT.GET_GROWTH_PLANS_MYGOALS(clientId), payload).pipe(
      tap((res: IMyGrowthResponse) => {
        if (res.ok) {
          this.growthPlansData = {...res};
        }
      }),
      switchMap(() => of(this.growthPlansData))
    );
  }

  public getGoalStatus(goal): string {
   switch (goal.type.toLowerCase()) {
    case 'skill':
      return `${goal?.currentValue || 0}/${goal?.requiredValue || 0}`;

    case 'role':
    case 'specialization':
    case 'certification':
      return goal?.progressStatus !== 'COMPLETED' ? 'Incomplete' : 'Completed';

    case 'course':
      return `${(goal?.currentValue || 0) * 100}/100 %`;

    case 'custom':
      if (goal?.measurement === 'COMPLETE_INCOMPLETE') {
        return goal?.progressStatus !== 'COMPLETED' ? 'Incomplete' : 'Completed';
      } else if (goal?.measurement?.startsWith('CURRENCY')) {
        return `${goal?.currentValue || 0}/${goal?.requiredValue || 0}${this.getCurrencySymbol(goal?.measurement)}`;
      } else if (goal?.measurement?.startsWith('PERCENTAGE')) {
        return `${goal?.currentValue || 0}/100 %`;
      } else {
        return `${goal?.currentValue || 0}/${goal?.requiredValue || 0}`;
      }
    default:
      return '';
   }
  }

  public getCurrencySymbol(measurement: string): string {
    switch (measurement) {
      case 'CURRENCY_INR':
        return ' ₹';
      case 'CURRENCY_USD':
        return ' $';
      case 'CURRENCY_EUR':
        return ' €';
      default:
        return '';
    }
  }

  public calculateChipPosition(chipname: string, position: number): number {
    const leftOffset = 15;
    const rightOffset = 20;
    const goalOffset = 10;
    if (chipname === Goal_Chip.GOAL_SETTING) {
      return position > leftOffset ? position - goalOffset : leftOffset;
    }
    return position > 50 ? 100 - position - leftOffset : 100 - position - rightOffset;
  }

  public updatePaginator(paginator: any, totalCount: number): void {
    if (!paginator) {
      setTimeout(() => {
        paginator?.calculatePageNumbers({ totalDataCnt: totalCount });
      }, 200);
    } else {
      paginator?.calculatePageNumbers({ totalDataCnt: totalCount });
    }
  }
  
}
